import imagesLoaded from 'imagesloaded';
import easing from 'jquery.easing';
import ScrollTrigger from '@terwanerik/scrolltrigger';
//import Swiper from 'swiper';
import Swiper from 'swiper/bundle';
import selectric from 'selectric';
import 'selectric/public/selectric.css';

export class App{
  constructor(){
    this.win = window;
    this.wrap = document.querySelector('.wrap');
    this.body = document.body;
    //this.btn = document.getElementById('btn');
    this.face = document.getElementById('face');
    this.loaded_cnt=0;
    this.loaded = false;
    this.max_loaded = 100;
    this.btn_hum = document.getElementById('btnHm');
    this.navContainer = document.getElementById('navContainer');
    this.menuContainer = this.navContainer.querySelector('.menuContainer');
    this.sc_btns = document.getElementById('socialContainer').querySelectorAll('.sc');
    // this.socialContainer = document.createElement('div');
    // this.socialContainer.classList.add('social_container');
    // for(const _btn of this.sc_btns){
    //   this.socialContainer.appendChild(_btn.cloneNode(true));
    // }
    // this.menuContainer.appendChild(this.socialContainer);
    this.menuBack = document.createElement('div');
    this.menuBack.classList.add('menuBack');
    this.menuContainer.appendChild(this.menuBack);
    this.header = document.getElementById('siteHeader');
    this.sp = 1200;
    this.ease = "easeInOutExpo";


  }
  init(){
    const _this = this;
    const ua = navigator.userAgent;
  	const isPC = !/Android|iPhone|iPad|iPod/.test(ua);

    this.imgLoaded = imagesLoaded(this.wrap);
    this.imgLoaded.on('always',load_always);
    this.imgLoaded.on('progress',load_progress);

    this.winH = this.win.innerHeight;
    this.winW = this.win.innerWidth;
    this.headerH = this.header.offsetHeight;

    if(this.body.classList.contains('home')){

      this.sec1 = document.querySelector('.sec1');
      if(this.sec1.classList.contains('sld')){
        this.btn_down = document.createElement('a');
        this.btn_down.classList.add('btn_down');
        this.btn_down.setAttribute('href',"#news");
        this.sec1.appendChild(this.btn_down);
        this.secNews = document.querySelector('.sec_news');
      }

      const btn_truck = document.getElementById('btn_truck');
      const vehicle = document.getElementById('vehicle');
      btn_truck.href = vehicle.href;
      // console.log(btn_truck);
      // console.log(vehicle.href);
      const theos = document.querySelectorAll('.mapContainer .theo');
      const num_arr = [];
      theos.forEach(
        (theo, index) => {
          num_arr.push(index);
          console.log(index, Math.floor(Math.random() * theos.length));
        }
      )
    }

    function load_always(instance){
      if(!_this.loaded){
        console.log('always');
        loaded_event();
      }
    }
    function load_progress(instance, image){
      _this.loaded_cnt++;
      if(_this.loaded_cnt > _this.max_loaded){
        console.log(_this.loaded_cnt);
        loaded_event();
      }
    }

    function loaded_event(){
      console.log('loaded');
      _this.loaded = true;
      _this.imgLoaded.off('progress',load_progress);
      _this.imgLoaded.off('always',load_always);
      _this.wrap.classList.add('on');
      $('.spinner').fadeOut('slow',function(e){
        $(this).remove();
      });

      setTimeout(()=>{
        if(_this.body.classList.contains('home')){

          _this.common_events();
          _this.events();

          
        }else if(_this.body.classList.contains('theolist')){
          const _url = location.href;
          if(_url.indexOf('?id=') != -1){
            const _id = _url.split("?id=");
            const _tar = _id[_id.length -1];
            const _el = document.getElementById(_tar);
            _this.anim(_el);
          }
          _this.nav_event();
        }else{
          _this.common_events();
          _this.events();
          //console.log('movie');
        }
      },100);

    }
  }
  menu_open(){
    this.btn_hum.classList.add('on');
    this.navContainer.classList.add('on');
    this.btn_hum.querySelector('.bar:nth-child(1)').style.animationName="bar1";
    this.btn_hum.querySelector('.bar:nth-child(3)').style.animationName="bar3";
  }
  menu_close(){
    console.log('close');
    this.btn_hum.classList.remove('on');
    this.navContainer.classList.remove('on');
  }
  nav_event(){
    this.menuBack.addEventListener('click',(e)=>{
      console.log(this.menu_close);
      this.menu_close();
    });

    this.btn_hum.addEventListener('click',(e)=>{
      console.log('click');
      if(this.btn_hum.classList.contains('on')){
        this.menu_close();
      }else{
        this.menu_open();
      }
    });

  }
  common_events(){
    //console.log(this.menuBack);
    this.nav_event();
    
    this.trigger = new ScrollTrigger();
    this.trigger.add('[data-def]',{
      once:true,
      offset:{
        viewport:{
          x:0,
          y:(trigger, rect, direction) => {
            return 0.15;
          }
        },
        element:{
          x:0,
          y:0
        },
      },
      toggle: {
        class: {
          in: 'animIn',
          out: 'animOut'
        }
      },
      callback: {
        in: null,
        visible: null,
        out: (trigger) => {
          console.log(trigger);
          return new Promise((resolve, reject) => {
            console.log(resolve);
            console.log(reject);
            setTimeout(resolve, 10);
          })
        }
      }
    });

    this.sels = document.querySelectorAll('select');
      this.sel_arr = [];
      for(const sel of this.sels){
        this.sel_arr.push($(sel).selectric());
      }


      if(this.body.classList.contains('page') && this.body.classList.contains('news')){
        const btnClear = document.getElementById('btnClear');
        const ops = document.querySelectorAll('.filterList select option');

        //console.log(sel_arr);
        btnClear.addEventListener('click',()=>{
          console.log('click');
          for(const op of ops){
            // sel.selectedIndex = -1;
            $(op).prop('selected',false);
            $(op).attr('selected',false);
            //console.log(sel);
          }
          for(const _sel of this.sel_arr){
            //console.log(_sel);
            _sel.selectric('refresh');
          }
        });
      }

  }
  anim(target){
    setTimeout((e)=>{
      const rect = target.getBoundingClientRect();
      let scTop = window.pageYOffset || document.documentElement.scrollTop;
      console.log(rect.top + " - "+ scTop);
      $('html,body').animate({
        scrollTop:rect.top + scTop - 60
      },this.sp,this.ease);
    },500);
  }
  events(){

    //common

    const atags = document.querySelectorAll('a[href^="#"]');
    for(const _a of atags){
      _a.addEventListener('click', (e)=>{
        e.preventDefault();
        let sp = 900;
        const ease = "easeInOutExpo";
        let href = e.currentTarget.getAttribute('href');
        if(href ==  "#" || ""){
          href = "site";
          sp = 1200;
        }else{
          href = href;
        }
        href = href.replace("#","");
        let target = document.getElementById(href);
        setTimeout((e)=>{
          const rect = target.getBoundingClientRect();
          let scTop = window.pageYOffset || document.documentElement.scrollTop;
          $('html,body').animate({
            scrollTop:rect.top + scTop - 60
          },sp,ease);
        },100);
      });
    }

    if(this.body.classList.contains('about')){
      console.log('about page');

    this.btn_spec = document.getElementById('btn_spec');
    this.btn_prof = document.getElementById('btn_prof');
    this.btn_closes = document.querySelectorAll('.btn_back');
    this.btn_sws = document.querySelectorAll('.btn_sw');
    this.swps = document.querySelectorAll('.swiper');

    this.mp3 = "mp3/201125_HelloImTheo.mp3";
    this.music = new Audio(this.mp3);
    this.playst = false;

    console.log(this.btn_sws);

    // this.btn.addEventListener('click',e=>{
    //   if(!this.playst){
    //     this.playst = true;
    //     this.music.play();
    //     this.btn.classList.add('on');
    //     this.face.classList.add('on');
    //   }else{
    //     this.playst = false;
    //     this.music.pause();
    //     this.music.currentTime = 0;
    //     this.btn.classList.remove('on');
    //     this.face.classList.remove('on');
    //   }
    //
    // });
    // this.music.addEventListener('ended',e=>{
    //   this.playst = false;
    //   this.music.currentTime = 0;
    //   this.btn.classList.remove('on');
    //   this.face.classList.remove('on');
    // });



    this.swObj = {
    }
    this.swiper = new Swiper('.sw_container',this.swObj);

    //if(this.body.classList.contains('about')){
      this.btn_spec.addEventListener('click',e=>{
        this.swiper.slideTo(1);
      });
      this.btn_prof.addEventListener('click',e=>{
        this.swiper.slideTo(0);
      });
    //}

    for(let swp of this.swps){
      let backcover = document.createElement('div');
      backcover.classList.add('backcover');
      swp.appendChild(backcover);
      backcover.addEventListener('click',e => {
        let sw = e.currentTarget.parentNode;
        if(sw.classList.contains('on')){
          sw.classList.remove('on');
        }
      });
    }

    //btn_sw
    for(const btn_sw of this.btn_sws){
      btn_sw.addEventListener('click',e=>{
        console.log('n_p');
        let parent = e.currentTarget.closest('.sec');
        if(e.currentTarget.dataset.type){
          let data = parseInt(e.currentTarget.dataset.type);
          //console.log(data);
          this.swiper.slideTo(data);
        }else{
          //console.log('no data');
        }
        setTimeout(e => {
          parent.querySelector('.swiper').classList.add('on');
        },200);
      });
    }
    for(let btn_close of this.btn_closes){
      btn_close.addEventListener('click',e=>{
        //console.log('close');
        let sw = btn_close.closest('.swiper');
        if(sw.classList.contains('on')){
          sw.classList.remove('on');
          setTimeout(e => {
            this.swiper.slideTo(0);
          },500);
        }
      });
    }
  }//about

  if(this.body.classList.contains('home')){
    // window.addEventListener('resize',(e)=>{
    //   this.resize();
    // });
    this.mv_elm = document.getElementById('mv');
    if(this.mv_elm != null){
      const stag = document.createElement('script');
      stag.src = "https://www.youtube.com/iframe_api";
      stag.setAttribute('id','ytscript');
      const sfirst = document.getElementsByTagName('script')[0];
      sfirst.parentNode.insertBefore(stag, sfirst);
    }else{
    this.sw_obj = {
      loop:true,
      speed:900,
      effect:'fade',
      autoplay:{
        delay:4000,
        disableOnInteraction:false
      }
    };
    const _sw = document.querySelector('.topslider');

    if(_sw != null){
      const sw_child = _sw.querySelectorAll('.swiper-slide');
      if(sw_child.length >= 1){
        this.top_swiper = new Swiper('.topslider',this.sw_obj);
        console.log(this.top_swiper);
      }
    }
  }

  //this.resize();

  }




}//events

resize(){
  this.getSize();
  if(this.winW <= 768){
    this.sec1.style.height=(this.winH - this.headerH)+"px";
  }else{
    this.sec1.style.height="";
  }
}

getSize(){
  this.winH = window.innerHeight;
  this.winW = window.innerWidth;
  this.headerH = this.header.offsetHeight;
  //console.log(this.headerH);
}


} //A
